/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "theme/animate.css";

.grid {
  background: url("./assets/img/bkg-nuages.png") no-repeat;
  background-size: cover;
  background-position: center;
  margin-inline: unset; // used to fix some margins
}

.card {
  box-shadow: 0 4.3px 7.6px 2.2px rgba(157, 124, 122, 0.15) !important;
  border-radius: 1rem !important;
  border: 0 !important;
  margin: 0;

  ion-card-header {
    padding-bottom: 0.5rem;
  }

  ion-card-title {
    font-size: 32px;
    font-weight: bold;
  }

  // margin for footer buttons
  & > ion-button {
    margin: 0.7rem;
    margin-top: 0;
  }
}

// Modification de la disposition des bouton dans le modal de la page Profile car la classe mr-2 qui est intégrée ne marche pas
.d-flex.flex-row.align-items-center.justify-content-between.mx-auto.py-2.px-3
  .flex {
  display: contents;
}

// Helpers //
.text-orange {
  color: #f08200;
}

.bold {
  font-weight: bold !important;
}

.text-white {
  color: #fff;
}

.text-secondary {
  color: var(--ion-color-secondary);
}

// style for auth pages
.auth-page {
  background-image: linear-gradient(
    135deg,
    var(--ion-color-dark),
    var(--ion-color-primary)
  );

  .grid {
    padding: 0;

    .col {
      padding: 0;
    }
  }

  .btn-group {
    .button-ios,
    .button-md {
      border-radius: 0;

      &:first-of-type {
        border-radius: 2px 0 0 2px;
      }

      &:last-of-type {
        border-radius: 0 2px 2px 0;
      }
    }
  }

  .auth-content {
    .list-form {
      padding: 0;
      margin-bottom: 0;

      ion-item {
        background: none;
        padding: 0;

        &:first-child {
          border-top: none;
        }

        &:after {
          display: none;
        }

        ion-label {
          font-size: 1em;
        }
      }
    }
  }
}

.my-alert-consent {
  --max-width: 90%;
}

@media (min-width: 992px) {
  .my-alert-consent {
    --max-width: 50%;
  }
}

.frame {
  height: 48px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0;
  border-radius: 6px;
  border: solid 2px #b1ddea;
  background-color: #fff;

  &:is(select) {
    --pr: 2rem;
    --chevron-size: 10px;
    padding-right: calc(var(--pr) + var(--chevron-size));
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,18' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
    background-repeat: no-repeat;
    background-position: right calc((var(--pr) + var(--chevron-size)) / 2)
      center;
    background-size: var(--chevron-size);
    appearance: none;
  }
}

.frame-error {
  height: 48px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0;
  border-radius: 6px;
  border: solid 2px #f08200;
}
.input-error {
  border-radius: 6px;
  border: solid 2px #f08200;
}

.type-toggle {
  color: #61b231;
}

.label {
  flex-grow: 0;
  // font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #4d4340;
}

.text-error {
  color: #f08200;
  font-weight: 600;
}

ion-button {
  --background: #61b231;
  --border-radius: 1rem;
  --box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  text-transform: none;
  font-weight: bold;
  --transition: 0.5s ease-in;
}

.my-button-cancel {
  --background: #e9e7dd;
  --color: #1f1f1f;

  &:hover {
    transform: scale(1.1);
    --transition-duration: 1s;
  }
}

.actions-favorites {
  --background: linear-gradient(90deg, #b2e7ee, #daffc5);
  --color: black;
}

.pilier-content-card {
  width: 90%;
  padding: 35px 40px 25.7px 40px;
  opacity: 0.9;
  border-radius: 20px;
  box-shadow: 0 4px 7px 3px rgba(157, 124, 122, 0.25);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.pointer {
  cursor: pointer;
}

// My Commons flex
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-row-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.flex-column-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-centrer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grow {
  flex-grow: 1;
}

.padding20 {
  padding: 20px;
}

.padding30 {
  padding: 30px;
}

.radius30 {
  border-radius: 30px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.top30 {
  top: 30px;
}

.padding-left-right-30 {
  padding-left: 30px;
  padding-right: 30px;
}

@media screen and (max-width: 768px) {
  //select options of question component
  .question-select-popover {
    &::part(content) {
      $selectOptionsWidth: 90vw;
      left: calc(50% - calc($selectOptionsWidth / 2));
      width: $selectOptionsWidth;
    }

    & ion-item {
      font-size: min(16px, 4vw);
      white-space: normal !important;

      & *[slot="start"] {
        margin-inline: 0vw 2vw !important;
      }

      & ion-label {
        white-space: normal !important;
      }
    }
  }
}

ion-popover {
  --width: auto;

  ion-checkbox {
    margin-right: 10px !important;
  }
}

ion-checkbox::part(container) {
  --border-color: var(--checkbox-background-checked);
}

ion-radio::part(container) {
  border-color: grey;
}

.citation-container {
  display: flex;
  flex-direction: column;
  gap: 15px;

  border-radius: 15px;
  padding: 18px;
  width: fit-content;
  margin: 15px auto;
}
.citation-body {
  font-weight: bold;
  font-style: italic;
  text-align: center;
  margin: 0;
}
.citation-source {
  font-weight: 300;
  text-align: center;
  margin: 0;
}
// quick and dirty (alignement checkbox dans les "actions"
.app-action-item {
  ion-item {
    display: flex !important;
    align-items: center !important;
  }
}

.labelWidthDefault {
  width: auto !important;
}
